<template>
  <div>
    <Table
      label="Review"
      :actions="actions"
      :items="$store.getters['review/tableData']"
      :fields="fields"
      :loading="$store.state.review.loadingTable"
      :filter="$store.state.review.tableFilter"
      :sort="$store.state.review.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.review.tableKeyword"
      :pagination="$store.state.review.tablePagination"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Date',
          type: 'datetime',
        },
        {
          key: 'service.name',
          label: 'Listing',
          type: 'profile',
          image: 'service.media[0].thumbUrl',
          url: {
            callback(data) {
              return `/service/${data.service.id}`
            },
          },
        },
        {
          key: 'review',
          label: 'Review',
          visibility: ['view'],
        },
        {
          key: 'stars',
          label: 'Rating',
          type: 'rating',
        },
        // TODO: order number
        // TODO: creator
        // TODO: creator email
        {
          key: 'fullName',
          label: 'Supporter',
          type: 'profile',
          image: 'avatar',
          sortable: false,
        },
      ],

      actions: ['view'],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('review/getTableData')
    },
    filterData(val) {
      this.$store.commit('review/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('review/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('review/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('review/SET_TABLE_KEYWORD', val)
    },
  },
}
</script>
